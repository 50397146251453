<template>
  <div class="container">
    <div class="index_section rehisSuccess_cs">
      <div class="index_logo"></div>
      <div class="welcome_cs">
        <p class="welcome_2">欢迎您注册成功</p>
        <p class="welcome_1">welcome to sign up</p>
        <p class="welcome_3"><span style="display: inline-block; width: 100px; text-align: right; margin-right: 10px;">您的登录账号:</span> <span style="display: inline-block; width: 80px; text-align: left">{{this.$route.query.phone}}</span></p>
        <p class="welcome_4"><span style="display: inline-block; width: 100px; text-align: right; margin-right: 10px;">您的登录密码:</span> <span style="display: inline-block; width: 80px; text-align: left">{{this.$route.query.password}}</span></p>
      </div>
          <van-popup
            v-model="show"
            closeable
            close-icon-position="top-left"
            position="bottom"
            :style="{ height: '80%' }"
          >
            <van-image
              width="100%"
              src="https://xiangquyouxuan.oss-cn-hangzhou.aliyuncs.com/xiangqu/cancer/internally/0cc5594b161440f58aea885b9d78cf1e.png"
            />
          </van-popup>
      <div class="btn_cs">
        <van-button type="info" @click="offLogin">立即登录</van-button>
        <van-button type="info" @click="showExplain">使用说明</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Notify } from 'vant'
import { Dialog } from 'vant'

let posswordReg = /^(\w){6,18}$/ //只能输入6-18位字母、数字、下划线
import bus from './bus.js'
export default {
  name: 'Index',
  accountNum: '-------',
  model: {
    prop: 'inputValue',
    event: 'input'
  },
  props: {
    /**
     * 当前输入的值
     */
    inputValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
        account: '18352616416',
        passwords: '116446',
        show: false,
      editpwdForm: {
        oldpwd: '', //原始密码
        newpwd: '', //新密码
        newpwd2: '' //二次确认新密码
      },
      show: false,
      password: this.inputValue,
      passwordType: 'password',

      name: '全息之眼-～～～～～～～～～'
    }
  },
  computed: {
    username() {
      let username = this.$cookies.get('ms_username')
      if (username != null || username != undefined) {
        username = username.split('-')
      }
      return username != null ? username : this.name
    },
    accountNum() {
      let accountNum = this.$cookies.get('ms_phone')
      return accountNum ? accountNum : this.accountNum
    }
  },
  methods: {
    showExplain(){
      this.show = true;
    },
    offLogin(){
      this.$router.push({
        path: '/',
        query: {}
      })
    }
  }
}
</script>

<style scoped>
</style>